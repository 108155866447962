@import './Global.scss';

.hero-wrap{
  height: 470px;
  width: 100vw;
  position: relative;
  overflow: hidden;
  img{
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
  >div{
    background-color: rgba(#f6f6f6,0.5);
    position:absolute;
    top:0;
    left: 0;
    z-index: 5;
    height: 470px;
    width: 100vw;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

.project-title{
  position:absolute;
  text-align: center;
  z-index: 10;
  top: 50%;
  left: 50%;
  // color: #414141;
  transform: translate(-50%, -50%);
  a{
    box-shadow: 3px 6px 30px rgba(rgb(82, 82, 82), 0.4);
  }
}

.img-size{
  width:100%;
  max-width: 600px;
  margin:1em;
}
.large-img{
  width: 100%;
  display: inline-block;
  max-width: 960px;
  margin: 1.5em auto;
}
.two-grid-content{
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  @include breakpoint('desktop'){
    grid-template-columns: 1fr 1fr;
  }
}
.three-grid-content{
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  @include breakpoint('desktop'){
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.thick-font{
  font-weight:700;
}
.process-list{
  display: flex;
  flex-direction:column;
  @include breakpoint('desktop'){
    flex-direction: row;
  }
  li{
    position: relative;
     padding-right:40px;
     z-index:2;
  }
}
.two-cols{
 @include breakpoint('desktop'){
  display:grid;
  align-items:center;
  grid-template-columns:40% 60%;
  gap:1em;
  // margin:2em 0;
  // padding:1em;
  //  border-top:1px solid $divider;
   //background-color:rgba(white, 0.8);
 }
}
.description-content{
  max-width: 714px;
  min-width: 325px;
  margin: 0 auto;
}

.strong{
  font-weight:600;
  color:$cream;
  // color: darken($themeThree, 15%);
}
.add-gap{
  margin-bottom: 1.5em;
}
.frame-style{
  border: 1px solid $btn-bg-primary;
  border-radius: 16px;
  padding: 1em;

  h6{
    text-align: center;
    font-weight: 500;
    padding: .5em;
    font-size:1.25em;
  }
}
.sub-title{
  font-weight: 500;
  font-size: 1.6em;
  text-align: center;
}
