@use './Global.scss'as*;

a{
  text-decoration: none;
   color: $cream;
   font-weight: 500;

}
.all-wrapper{
  position: relative;
  align-items: center;
  background-color: rgba(white, 0.8);
}
.project-wrapper{
  position: relative;
  display: grid;
  justify-items: center;
  margin:4em 3em;
  padding-bottom: 2em;
  // border-bottom:1px solid $divier;
  @include breakpoint("desktop"){
    grid-template-columns: 50% 50%;
    align-items: center;
  }
  &:last-child{
    border: 0;
}
}
.content-wrapper{
  // text-align: center;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  overflow: inherit; 
   @include breakpoint("mobile"){
    flex-wrap: wrap;
    // margin:1em 3em 5em;   

   }
  .overlay {
    // position: absolute;
    // bottom:-20%;
    width: 100%;
    overflow: hidden;
  }
}
.text {
  color:$cream;
  text-align: center;
  padding: 0.2em;
  margin:1em 0;
  h3{
    font-weight: 500;
    font-size: clamp(0.8rem, 2vw, 1.5rem);
  }
  P{
    font-weight: 500;
    font-size:clamp(0.8rem, 2vw,1rem);
  }
}
.description{
  // color:darken($color: $themeThree, $amount: 10);
  color:$cream;
  padding:1em 2em;
  text-align: left;
  max-width: 550px;

  h6{
    // font-weight: 600 !important;
    font-size: clamp(1.2rem, 1.8vw, 2rem);
    margin-bottom: 0.5em;
  }
  p{
    margin-bottom: 0.5em;
  }
}

.img-container{
  // visibility: hidden;
  position: relative;
  width: 100%;
  // height: 100%;
  overflow: hidden;
}
.img {
  // height: 100%;
  width: 90%;
  min-width: 280px;
  max-width:500px;
  object-fit: contain;
  @include breakpoint("tablet"){
    object-fit: cover;
  }
}
.subtitle{
  font-size: clamp(0.8rem, 2vw,0.9rem);
  padding-bottom: 1em;

}
.btn-group{
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap:1em;
  @include breakpoint('tablet'){
    margin:1em;
  }
}
.bg-circle{
  display: none;
  @include breakpoint("desktop"){
    display: block;
    padding:1em 0.6em;
    background-color: $themeTwo;
    border-radius:500px;
    position: absolute;
    z-index:3;
    top:0;
    left:0;
    font-size: clamp(2rem, 5vw, 8rem);
    text-align: center;
    font-weight: 600;
    color: transparent;
    -webkit-text-stroke:1px $themeThree;
    left: -15%;
  }
}
.ux-hero-wrapper{
  position: relative;
  overflow: hidden;
  max-height: 500px;
  width: 100%;
}
.ux-hero-holder{
  overflow: hidden;
}
.ux-hero{
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  transition: transform 0.5 ease-in;
}
.img-animation{
  opacity: 1;
  transform: translateX(0);
}
.control-btns{
  position:absolute;
  z-index: 10;
  left: 60vw;
  bottom:5vh;
  span{
    display: inline-block;
    width: 50px;
    margin-right: 1em;
    border-bottom: 1px solid $themeThree;
    cursor: pointer;
  }
}

.ui-wrapper{
  margin-top:150px;
 h1{
  font-size: clamp(3rem, 5vw, 20rem);
  // font-weight: 500;
  color:$cream;
  text-align: center;
 }
}
.gallery-section{
  padding-bottom: 2em;
  border-bottom: 1px $divider solid;
  margin:5em 1em;
}
.gallery-intro{
  margin-left: 5em;
}
.gallery-img{
  display: flex;
  justify-content: center;
}

.mentions-wrap{
  display: grid;
  justify-content: center;
  text-align:center;
  margin:1em;
  h2{
     font-size:clamp(1.75rem, 1.6rem+0.4vw, 2rem);
     margin-bottom:"2em";
  }
}
.companies{
  display: flex;
  flex:1 1 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  img{
    min-width: 150px;
    max-width: 220px;
    margin:1em 2em;
  }
}


