@import './Global.scss';
.title{
  position: relative;
  z-index: 2;
  margin:3em auto 2em 3em;
  display: grid;
  justify-items:flex-start;
 p{
   font-size: clamp(16px, 2vw, 18px);
 }
}
.name{
  font-size: clamp(1.5rem, 2vw, 2rem);
  // font-weight: 500;
  margin-bottom: 1em;
  text-align: left;
  overflow: hidden;
  // color:$themeThree
}
.name span{
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
}
.profile-img{
  display: flex;
  justify-content: center;
  position:relative;
  max-width: 380px;
  min-width: 280px;
  width: 100%;
  margin:0 auto;
 img{
  width: 100%;
  max-width: 380px;
  min-width: 280px;
  opacity: 0;
 }
}
.ball{
  content:" ";
  position:absolute;
  bottom: 10px;
  left: 25%;
  border-radius:50%;
  display: inline-block;
  height:20px;
  width:20px;
  background: $themeColor;
}
h4{
  position: relative;
}

.intro{
  max-width: 700px;
  margin:2em auto 2em auto;
  font-weight: 400;
  p{
    overflow: hidden;
  }
 span{
   opacity:0;
   display: inline-block;
   transform: translateY(100%);
 }
}
.home-btn-group{
  height: 170px;
}
.link-content{
  h1{
    font-size: clamp(3rem, 2vw, 5rem);
    // font-weight: 500;
    opacity: 0;
    text-align: center;
    // color:$themeThree;
  }
}
.link-content-ux{
  @extend .link-content;
  top:50px;
  @include breakpoint('mobile'){
  left:0;
  }
}
#two-rings{
  position: absolute;
  display: flex;
  justify-content: center;
  top:0;
  left: 0;
  width: 100%;
  max-width: 380px;
  min-width: 280px;
}
.ring{
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  fill:transparent;
  stroke:#a58780;
  stroke-miterlimit:10;
  animation: dash 3s linear forwards;
}
@keyframes dash {
  from{
    stroke-dashoffset: 1;
  }
  to{
    stroke-dashoffset: 0;
  }
}
@keyframes floatingUp {
  from{
    transform:translateY(-8px)
  }
  to{
    transform:translateY(0px)
  }
}

.scroll-down{
  opacity: 0;
  position: relative;
  p{
    font-weight: 500;
    // font-size: 21px;
  }
  span{
    content:"";
    position: absolute;
    height: 45px;
    width: 45px;
    border-radius: 30px;
    background-color: $themeTwo;
    opacity: 0.7;
     left:-80px;
    z-index:3;
  }
}
.hide-arrow{
  img[alt="arrow"]{
    opacity: 0;
    // visibility: hidden;
  }
}
