@import './Global.scss';
$progressWidth: 25px;

.side-bar{
  @include breakpoint("desktop"){
    border-left: 1px $divider solid;
  }
}
.skill-name{
  display: block;
  padding-left: 20px;
}
.greet{
  text-align: left;
  margin:50px 30px;
  &_subtitle{
    margin-bottom:0.6em;
  }
  div{
    margin-bottom: 30px;
  }
}
.profile{
  position: relative;
   h2{
     display: block;
     margin-top:50px;
   }
}
.skill{
  margin:80px 0;
  text-align: center;
  position: relative;
}
.section-title{
  position: relative;
  &::before{
    position:absolute;
    content:" ";
    display: block;
    margin-top:50px;
    padding-right:30px;
    display: inline-block;
    height:5px;
    width:40px;
    margin-left: 50px;
    border-radius: 50px;
    background: $divider;
    transition: width 0.4 ease-in 0.8;
}
&::before{
  width: 80px;
}
}
.skill-box{
  padding: 24px;
  margin:16px 24px;
   border:1px solid $divider;
  border-radius: 16px;
  text-align: center;
  h5{
    // color:$themeThree;
    font-weight: 500;
  }
  li{
    margin:0.2em;
  }
  p{
    font-weight: 400;;
  }
}

