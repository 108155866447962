// $primaryColor:#FAF2EB;
$secondaryColor:#F9D138;
$darkThemeColor:#705973;
$themeColor:#BA93BF;
// $cream:#F1E7DD;

$cream: #331F00;

$themeOne: #99A580;
$themeTwo:#EED492;
$themeThree: #A58780;


// NEW ADDED TOKENS
//$cream:#5c4b48;
// $cream:#141414;
// $themeThree:#323232;
$primaryColor: #FFFBF5;
$divider: #EBDFD7;
// $btn-bg: #D2B69C;
$btn-text-color:#331f00;
// $btn-bg-hover:#f7c706;
$btn-border-radius: 10px;
// $btn-bg-primary:#ffd426;
$btn-bg-primary: #EAC974;
//$btn-bg-primary:#D5BDAF;
$btn-bg-hover:#E5BD58;

@mixin breakpoint($point){
  @if($point=="mobile"){
    @media (max-width:679px){
      @content;
    }
  }
  @else if($point=="tablet"){
    @media (min-width:681px){
      @content;
    }}
    @else if($point=="desktop"){
      @media (min-width:821px){
        @content;
      }
}
}

@mixin prefix ($properity, $value, $prefixes){
 @each $prefix in $prefixes {
   -#{$prefix}-#{$properity}:$value;
 }
 #{$properity}:$value;
}
html{
  font-size: 16px;
}
*{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  letter-spacing: 0.5px;
}
body{
 background-color: $primaryColor;
  box-sizing: border-box;
  margin:0;
  color:$cream;
}
h1,h2,h3,h4,h5, h6{
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  margin:0;
  line-height:1.5;  
  font-weight: 500;
}
h2{
  font-size: clamp(1.45rem, 2vw, 2.625em);
}
h3{
  font-size: clamp(1.21rem, 2.5vw, 1.45rem);
}
h4{
  font-size: clamp(1.23rem, 2vw, 1.26rem);
}
h5{
  font-size: clamp(1.18rem, 2vw, 1.22rem);
}
p{
  font-size:clamp(14px, 2.5vw, 16px);
  font-weight: 500;
  line-height:1.5;  
}
ul{
  padding: 0;
}
li{
  list-style: none;
  p{
    margin:0;
  }
}
.home-intro{
  @include breakpoint('desktop'){
    height: 100vh;
  }
}
.side-margin{
  position: relative;
  justify-items: center;
  align-items: center;
  margin-top:3em;
  @include breakpoint("tablet"){
    max-width: 800px;
  }
  @include breakpoint("desktop"){
    max-width: 1250px;
  }
}
.top-margin{
  margin-top:6em;
}
.magrin-top-bottom{
  margin-bottom:20px;
  margin-top:15px;
  justify-content:center;
  justify-items:center;
}

.line{
  display:inline-block;
  padding: 0.4em 0;
  margin:0 auto;
  text-align: center;
  font-weight: 500;
  font-size: clamp(17px, 5vw, 35px);
  // color:darken($themeThree, 10%);
  color:$cream;
}
.header-subtitle{
  font-size: 17px;
  font-weight:500;
}
.highlight-title{
//  color: darken($themeThree, 10%);
color:$cream;
 font-weight: 500;
 margin-bottom: 0;
}

.btn-style{
    font-size: clamp(0.8rem, 2vw, 1rem);
    font-weight: 500;
    padding: 16px 20px;
    margin:0;
    color:$btn-text-color;
    min-width: 120px;
    position: relative; 
    text-decoration: none;
    border-radius:$btn-border-radius;
}
.btn-primary{
  background-color: $btn-bg-primary;
}
.btn-secondary{
  background-color:white;
  border: 1.5px solid darken($btn-bg-primary, 10%);
}
// .btn-style:hover{
//   box-shadow: 3px 6px 20px rgba(rgb(82, 82, 82), 0.2);
// }
.animate-btn span{
  position: relative;
  z-index: 3;
  // font-weight: 600;
}
.animate-btn::before{
  content: "";
  z-index: 2;
  position: absolute;
  background-color:$btn-bg-hover;
  // background-color:darken($themeTwo, 10%);
  top:0;
  left:0;
  // border-radius: 50px;
  border-radius: $btn-border-radius;
  width: 0%;
  height: 100%;
  transition: all 0.2s ease-out;
}
.animate-btn:hover::before{
  width: 100%;
}
.btn-link{
  @extend .btn-style;
  text-decoration: none;
  display: inline-block;
  min-width: 160px;
  // margin:1.5em 2em;
  margin:8px;
  font-size: 16px;
  text-align: center;
  background-color: $btn-bg-primary;
}
.text-center{
  text-align: center;
  margin-top:1.5em;
  // margin-bottom: 0.3em;
}
.list-style{
  display: inline-block;
  // margin-left: 1em;
  &::before{
    content:" ";
    border-radius:50%;
    margin-right: 5px;
    display: inline-block;
    height:8px;
    width:8px;
    background-color: $btn-bg-primary;
    // background:$secondaryColor;
  }
}
.icon-size{
  width: 50px;
}
.diagram{

  @include breakpoint("mobile"){
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  background-color:rgba($color: #ffedbe, $alpha: 0.5);
  border-radius: 1em;
  padding: 2em;
  li{
    width: 300px;
    text-align: center;
    margin-right: 3em;
  }
}
.video-wrapper{
  margin: 50px 10px;
  justify-content: center;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
}
.video{
  position: absolute;
  top: 0;
  left: 0;
}
.large-font{
  font-weight: 800;
  width:clamp(22px, 2vw, 48px);
}
.mdl-grid{
  justify-content: center !important;
}
.btn-group-section{
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  background-color: darken($primaryColor, 4%);
  padding: 2em 4em 4em 4em;

  @include breakpoint("desktop"){
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }

}
.pre-section{
  display: inline-block;
   margin:2em 1em 1em 0;
  transition: transform 0.3s ease-out;
  position: relative;
  &:hover{
    transform: translateY(-8px);
  }
p{
    color:$btn-text-color;
    font-weight: 500;
    display: inline-block;
    margin: 0;
  }
  img{
    width:6px;
    color:$btn-text-color;
    margin-right: 1em;
  }
  &::after{
    content: "";
    width: 80px;
    height: 1.5px;
    border-radius: 50;
    background-color: $btn-bg-primary;
    position: absolute;
    top: 28px;
    left: 25px;
    z-index: 2;
  }
}

.next-section{
  @extend .pre-section;
 img{
  transform: rotate(-180deg);
      margin-left: 0.5em;
 }
}

//STYLE FOR FOOTER JS
.footer-section{
  margin: 10em 2em 6em 2em;
  text-align: center;
  position: relative;

  h5{
    margin-bottom: 1em;
  }
}

.icon-wrap{
  display: flex;
  justify-content: space-between;
  margin: 2em 1em;
  img{
    width: 24px;
    padding: 1em;
    transition: transform 0.3s ease-out;
   &:hover{
    transform: translateY(-5px);
   }
  }
}

.shape-bg{
  width: 100%;
  position: absolute;
  top:-120px;
  left: 0px;
  z-index: -5;
}
